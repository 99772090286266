<template>
  <!--
  *@des: 高层次人才购房补贴
  -->
  <section class="form-wrapper">
    <van-form ref="form">
      <van-field
        v-model="form.applyName"
        name="applyName"
        label="姓名"
        placeholder="请输入姓名"
        required
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypes"
        :fieldProps="{
          label: '证件类型',
          required: true,
          placeholder: '请选择证件类型',
        }"
      ></van-field-select>
      <div style="display: flex">
        <van-field
          v-model="form.idCardNum"
          name="idCardNum"
          label="证件号码"
          placeholder="请输入证件号码"
          required
        />
        <van-button class="idCardNum-button" @click="gccrcsbhy"
          >查询证书</van-button
        >
      </div>
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
        maxlength="11"
      />
      <van-field-area
        v-model="form.domicile"
        name="domicile"
        :fieldProps="{
          required: true,
          label: '户籍地址',
          placeholder: '请选择户籍地址',
        }"
        defaultField=""
      />
      <van-field
        v-model="form.highTalentType"
        name="highTalentType"
        label="高层次人才类别"
        placeholder="请输入高层次人才类别"
        required
        :disabled="hasHighTalent"
      />
      <van-field
        v-model="form.bankId"
        name="bankId"
        label="社会保障卡开户银行"
        placeholder="请输入社会保障卡开户银行"
        required
        maxlength="100"
      />
      <van-field
        v-model="form.bankAccount"
        name="bankAccount"
        label="社会保障卡金融账号"
        placeholder="请输入社会保障卡金融账号"
        required
        maxlength="20"
      />
      <van-field
        v-model="form.workCmpy"
        name="workCmpy"
        label="工作单位"
        placeholder="请输入工作单位"
        required
        maxlength="100"
      />
      <van-field
        :value="houseStr"
        name="house"
        clickable
        label="购买住房地址"
        placeholder="请选择购买住房地址"
        required
        @click="showHousePicker = true"
      />
      <van-popup v-model="showHousePicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="houseCitys"
          @cancel="showHousePicker = false"
          @confirm="onHouseConfirm"
        />
      </van-popup>
      <van-field
        v-model="form.houseAddress"
        name="houseAddress"
        label="购房详细地址"
        placeholder="请输入购房详细地址"
        required
        maxlength="100"
      />

      <!-- 家庭关系 -->
      <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }"
          >家庭关系</van-divider
        >
        <div v-for="(item, i) in form.family" :key="i">
          <van-field-select
            v-model="item.familyTies"
            :options="familyTies"
            :fieldProps="{
              label: '关系',
              required: item.familyName || item.famIdCardNum ? true : false,
              placeholder: '请选择关系',
            }"
          ></van-field-select>
          <van-field
            v-model="item.familyName"
            name="familyName"
            label="姓名"
            placeholder="请输入姓名"
            :required="item.familyName || item.famIdCardNum ? true : false"
            maxlength="100"
          />
          <van-field
            v-model="item.famIdCardType"
            name="famIdCardType"
            label="证件类型"
            placeholder="请输入证件类型"
            :required="item.familyName || item.famIdCardNum ? true : false"
            maxlength="100"
            disabled
          />
          <van-field
            v-model="item.famIdCardNum"
            name="famIdCardNum"
            label="身份证号"
            placeholder="请输入身份证号"
            :required="item.familyName || item.famIdCardNum ? true : false"
            maxlength="100"
          />
          <van-field-area
            v-model="item.famDomicile"
            name="famDomicile"
            :fieldProps="{
              required: item.familyName || item.famIdCardNum ? true : false,
              label: '户籍所在地',
              placeholder: '请选择户籍所在地',
            }"
            defaultField=""
          />
          <p class="fam-btns">
            <van-button class="btn" type="default" size="mini" @click="onAdd"
              >新增</van-button
            >
            <van-button
              class="btn"
              type="danger"
              size="mini"
              @click="onDelete(i)"
              >删除</van-button
            >
          </p>
        </div>
      </div>
    </van-form>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import { gccrcsbhy } from "@/api/policy/apply";

export default {
  name: "hight-house-subsidy",
  mixins: [formMixin],
  data() {
    return {
     
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        phone: "",
        idCardNum: "", //证件号码
        highTalentType: "", //高层次人才类别
        bankId: "", //社会保障卡开户银行
        bankAccount: "", //社会保障卡金融账号
        domicile: [], //户籍地址
        workCmpy: "", //工作单位
        house: "",
        houseAddress: "",
        family: [
          {
            familyTies: "",
            familyName: "",
            famIdCardType: "居民身份证",
            famIdCardNum: "",
            famDomicile: [],
          },
        ],
      },
      formRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
          },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请检查手机号格式",
          },
        ],
        bankId: {
          required: true,
          message: "请输入开户银行",
        },
        bankAccount: {
          required: true,
          message: "请输入金融账号",
        },
        workCmpy: {
          required: true,
          message: "请输入工作单位",
        },
        domicile: {
          type: "array",
          required: true,
          message: "请选择户籍地址",
        },
        house: {
          type: "array",
          required: true,
          message: "请选择购房地址",
        },
        houseAddress: {
          required: true,
          message: "请输入详细地址",
        },
      },
      formValidator: null,
      familyformRules1: {
        familyTies: {
          required: false,
        },
        familyName: {
          required: false,
        },
        famIdCardNum: {
          required: false,
        },
        famDomicile: {
          required: false,
        },
      },
      familyformRules2: {
        familyTies: {
          required: true,
          message: "请选择家庭成员关系",
        },
        familyName: {
          required: true,
          message: "请输入姓名",
        },
        famIdCardNum: [
          {
            required: true,
            message: "请输入身份证号",
          },
          {
            pattern:
              /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
            message: "请检查身份证号格式",
          },
        ],
        famDomicile: {
          required: true,
          type: "array",
          message: "请选择户籍地址",
        },
      },
      familyFormValidator1: null,
      familyFormValidator2: null,
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },
        {
          value: "护照",
          label: "护照",
        },
      ],
      showHousePicker: false,
      houseCitys: [
        {
          value: "41",
          text: "河南省",
          children: [
            {
              value: "4101",
              text: "郑州市",
              children: [
                {
                  value: "410173",
                  text: "航空港区",
                },
                {
                  value: "410122",
                  text: "中牟县",
                },
                {
                  value: "410184",
                  text: "新郑市",
                },
              ],
            },
            {
              value: "4102",
              text: "开封市",
              children: [
                {
                  value: "410223",
                  text: "尉氏县",
                },
              ],
            },
          ],
        },
      ],
      //家庭关系
      familyTies: [
        {
          value: "配偶",
          label: "配偶",
        },
        {
          value: "儿子(未成年)",
          label: "儿子(未成年)",
        },
        {
          label: "女儿(未成年)",
          value: "女儿(未成年)",
        },
      ],
      hasHighTalent: true,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.global.userInfo;
    },
    houseStr() {
      if (!this.form.house.length) {
        return "";
      } else {
        let [a, b, c] = this.form.house;
        let pro = this.houseCitys.filter((item) => item.value === a)[0];
        let city = pro.children.filter((item) => item.value === b)[0];
        let county = city.children.filter((item) => item.value === c)[0];
        return `${pro.text}/${city.text}/${county.text}`;
      }
    },
  },
  created() {},
  methods: {
    /*
     *@des: 获取高层次人才信息
     */
    gccrcsbhy() {
      if (
        !this.form.applyName ||
        !this.form.idCardNum ||
        !this.form.idCardType
      ) {
        this.$toast("用户名与证件号码不能为空");
        return;
      }
      const idCardRule =
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      if (
        "居民身份证" == this.form.idCardType &&
        !idCardRule.test(this.form.idCardNum)
      ) {
        this.$toast("证件号格式不正确");
        return;
      }
      gccrcsbhy({
        perCard: this.form.idCardNum,
        perName: this.form.applyName,
        type: "居民身份证" == this.form.idCardType ? "1" : "0",
      })
        .then((res) => {
          let { success, data } = res;
          if (success) {
            this.form.highTalentType = data[0].endCertificateName;
          } else {
            this.$toast("暂无高层次人才认证,请手动输入高层次人才类别");
            this.hasHighTalent = false;
          }
        })
        .catch((e) => {
          this.$toast("暂无高层次人才认证,请手动输入高层次人才类别");
          this.hasHighTalent = false;
        });
    },
    /*
     *@des: 选择购房地址
     */
    onHouseConfirm(a, b) {
      let [index1, index2, index3] = b;
      this.form.house = [
        this.houseCitys[index1].value,
        this.houseCitys[index1].children[index2].value,
        this.houseCitys[index1].children[index2].children[index3].value,
      ];
      this.showHousePicker = false;
    },
    /*
     *@des: 新增家庭成员
     */
    onAdd() {
      this.form.family.push({
        familyTies: "",
        familyName: "",
        famIdCardType: "居民身份证",
        famIdCardNum: "",
        famDomicile: [],
      });
    },
    /*
     *@des: 删除家庭成员
     */
    onDelete(index) {
      if (this.form.family.length === 1) {
        this.$toast("最后一条不能删除");
        return;
      }
      this.form.family.splice(index, 1);
    },
    /*
     *@des: 初始化校验
     */
    initFormValidator() {
      const idCardRule = [
        {
          required: true,
          message: "请输入证件号",
          trigger: "blur",
        },
        {
          pattern:
            /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
          message: "证件号格式不正确",
          trigger: "blur",
        },
      ];
      const passPortRule = {
        required: true,
        message: "请输入证件号",
        trigger: "blur",
      };
      if ("居民身份证" == this.form.idCardType) {
        this.formRules.idCardNum = idCardRule;
      } else {
        this.formRules.idCardNum = passPortRule;
      }
      this.formValidator = new Schema(this.formRules);
      this.familyFormValidator1 = new Schema(this.familyformRules1);
      this.familyFormValidator2 = new Schema(this.familyformRules2);
    },
    /*
     *@des: 校验家庭关系
     */
    async validateFamilyForm() {
      let len = this.form.family.length,
        isFormCorrect = true; //校验状态

      for (let i = 0; i < len; i++) {
        let item = this.form.family[i];
        try {
          //判断姓名，身份证号是否已填，不同校验
          if (item.familyName || item.famIdCardNum) {
            await this.familyFormValidator2.validate(item);
          } else {
            await this.familyFormValidator1.validate(item);
          }

          isFormCorrect = true;
        } catch ({ errors }) {
          isFormCorrect = false;

          let message = errors[0].message;
          this.$toast(`家庭关系第${i + 1}项${message}`);
          break;
        }
      }

      return isFormCorrect;
    },
    /*
     *@des: 校验表单
     */
    validate() {
      this.initFormValidator();
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            let familyFormStatus = await this.validateFamilyForm();
            if (familyFormStatus) {
              let family = this.form.family
                .filter((item) => (item.familyName ? true : false))
                .map((item) => {
                  let [famProCode, famCityCode, famCountyCode] =
                    item.famDomicile;
                  return {
                    ...item,
                    famProCode,
                    famCityCode,
                    famCountyCode,
                  };
                });
              let [proCode, cityCode, countyCode] = this.form.domicile;
              let [houseProCode, houseCityCode, houseCountyCode] =
                this.form.house;
              resolve({
                ...this.form,
                proCode,
                cityCode,
                countyCode,
                houseProCode,
                houseCityCode,
                houseCountyCode,
                family,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 0.2rem;
  background: #fff;
  .title-gutter {
    width: fit-content;
    margin-left: 0.3rem;
    padding: 0.3rem 0.1rem 0rem 0rem;
    font-size: 0.26rem;
    line-height: 0.26rem;
    border-bottom: 2px solid #3c37d2;
  }
  .fam-btns {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    .btn {
      width: 1.5rem;
      margin-right: 0.15rem;
    }
  }
}
.tip {
  margin: 0.2rem;
}
.idCardNum-button {
  width: 2rem;
  padding: 0 0.15rem;
}
</style>